import React from 'react';
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import TypewriterComponent from 'typewriter-effect';
import Button from 'components/button';
import './styles.scss';

const BannerSection = ({ title = '', desc = '', bannerText = '', isAbout, animatedText, headText = 'Who are we?', isHome = false }) => {
	let navigate = useNavigate();
	return (
		<Col span={24} className="top_section" id="home">
			<img src={require('../../assets/images/homeLeft.png')} className="home_left" />
			<img src={require('../../assets/images/homeRight.jpg')} className="home_right" />
			<Row justify={'center'}>
				<Col sm={24} xl={12} className="container">
					<span className="title">{title}</span>
					{animatedText && (
						<>
							<div className="animate_text_area">
								<h1>Welcome to</h1>
								<TypewriterComponent
									onInit={(typewriter) => {
										typewriter
											.typeString('Ultimate destination')
											.pauseFor(1000)
											.deleteAll()
											.typeString('Cricket betting')
											.pauseFor(1000)
											.deleteAll()
											.typeString('Thrill of every match')
											.pauseFor(1000)
											.deleteAll()
											.typeString('Live Betting')
											.pauseFor(1000)
											.deleteAll()
											.start();
									}}
									options={{
										loop: true,
									}}
								/>
							</div>
						</>
					)}
					{isAbout && <h1>{headText}</h1>}
					{bannerText && <h1>{bannerText}</h1>}
					{desc && (
						<p
							className="desc"
							{...(!isHome && {
								style: {
									fontSize: '16px',
									padding: '20px 0',
								},
							})}>
							{desc}
						</p>
					)}
					{/* <Button className="home_button" onClick={() => navigate('/contact-us')}>
						Connect with us
					</Button> */}
				</Col>
			</Row>
		</Col>
	);
};

export default BannerSection;
