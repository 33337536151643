import React from 'react';
import { Row, Col } from 'antd';
import Footer from 'components/footer';
import BannerSection from 'components/top-section';
import LeadingCompanies from './leading-companies';
import ScrollToHashElement from './ScrollToHashElement';
import OurClients from './our-clients';
import WhyChoose from './why-choose';
import FeaturedResources from './featured-resource';
import ToSupportUs from './to-support-us';
import OurServices from './our-services';
import './home.scss';

export default function Home() {
	return (
		<Row
			justify={'center'}
			style={{
				paddingTop: 20,
			}}>
			<ScrollToHashElement />
			<BannerSection
				isHome
				title="Bid Big, Win Bigger!"
				bannerText="Get ready to experience the thrill of every match like never before."
				animatedText
			/>
			<Col xl={20} sm={24}>
				<WhyChoose />
			</Col>
			<Col span={20}>
				<LeadingCompanies />
			</Col>
			<Col xl={20} sm={24}>
				<OurServices />
			</Col>
			{/* <OurClients /> */}
			{/* <Col xl={20} sm={24}>
				<ToSupportUs />
			</Col> */}
			{/* <Col xl={20} sm={24}>
				<FeaturedResources />
			</Col> */}
			<Footer />
		</Row>
	);
}
