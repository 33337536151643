import React from 'react';
import { Col, Row } from 'antd';
import BannerSection from 'components/top-section';
import Footer from 'components/footer';
import HTML from 'assets/svgs/technology/html.svg';
import CSS from 'assets/svgs/technology/css.svg';
import JS from 'assets/svgs/technology/js.svg';
import AdobeXD from 'assets/svgs/technology/adobeXD.svg';
import Figma from 'assets/svgs/technology/figma.svg';
import Invision from 'assets/svgs/technology/invision.svg';
import Photoshop from 'assets/svgs/technology/photoshop.svg';
import Illustrator from 'assets/svgs/technology/illustator.svg';
import AfterEffects from 'assets/svgs/technology/aftereffects.svg';
import TechnologyShowcase from './technology-showcase';
import './technology.scss';

const DATA = [
	{
		firstText: 'UI/UX',
		secondText: 'Design',
		description:
			'Elevate user satisfaction with our comprehensive UI/UX solutions. From intuitive designs to seamless interactions, we specialize in creating captivating digital experiences.',
		list: [
			{
				title: 'Languages',
				data: [
					{
						name: 'HTML',
						icon: HTML,
					},
					{
						name: 'CSS',
						icon: CSS,
					},
					{
						name: 'JavaScript',
						icon: JS,
					},
				],
			},
			{
				title: 'Tools',
				data: [
					{
						name: 'Adobe XD',
						icon: AdobeXD,
					},
					{
						name: 'Figma',
						icon: Figma,
					},
					{
						name: 'Invision',
						icon: Invision,
					},
					{
						name: 'Abode Photoshop',
						icon: Photoshop,
					},
					{
						name: 'Abode Illustrator',
						icon: Illustrator,
					},
					{
						name: 'After Effects',
						icon: AfterEffects,
					},
				],
			},
		],
	},
];

const Technology = () => {
	return (
		<Row className="technology" justify={'center'}>
			<Col span={24}>
				<BannerSection
					isAbout
					title="TECHNOLOGY"
					headText={'Technology Stack'}
					desc={
						'We pride ourselves on being at the forefront of technological advancement, delivering innovative solutions that drive business growth and transformation. With our deep expertise and relentless commitment to excellence, we offer a comprehensive suite of technology capabilities tailored to meet the evolving needs of our clients.'
					}
				/>
			</Col>
			{DATA?.map((data, index) => (
				<TechnologyShowcase
					key={index}
					firstText={data?.firstText}
					secondText={data?.secondText}
					description={data?.description}
					list={data?.list}
				/>
			))}

			<Col span={20}>
				<p className="footer_text">
					Our dedication to staying up-to-date with the latest advancements in technology allows us to deliver innovative solutions that
					empower businesses to thrive in today's digital landscape. Get in touch with us to learn more about how our technology stack can
					drive success for your business
				</p>
			</Col>
			<Col span={24}>
				<Footer />
			</Col>
		</Row>
	);
};

export default Technology;
