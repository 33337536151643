import React from 'react';
import CollapsibleComponent from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';

const Collapsible = ({ title = '', description = '' }) => (
	<CollapsibleComponent trigger={[title, <BsChevronDown />]} accordionPosition>
		<p>{description}</p>
	</CollapsibleComponent>
);

export default Collapsible;
