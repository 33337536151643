import React from 'react';
import { Col, Row } from 'antd';
import Image from '../../assets/svgs/our-mission/our-mission.svg';

const OurMission = () => {
	return (
		<Col span={24}>
			<Row className="our_mission">
				<Col sm={24} xl={14} className="right_area">
					<Row>
						<Col sm={24} xl={24}>
							<div className="text_area">
								<div className="title">
									<h1>
										Our <span>Mission</span>
									</h1>
								</div>
								<span className="subtitle">Empowering Digital Transformation</span>
								<p className="desc">
									To empower businesses through the seamless integration of cutting-edge digital technologies. We aspire to turn
									visionary ideas into reality, providing cost-effective digital business solutions that accelerate our clients'
									return on investment. Upwyse is committed to being a reliable partner in your digital transformation journey.
								</p>
							</div>
						</Col>
					</Row>
				</Col>
				<Col sm={24} xl={2} />
				<Col sm={24} xl={8} className="left_area">
					<img className="image" src={Image} alt="leading companies" />
				</Col>
			</Row>
		</Col>
	);
};

export default OurMission;
