import React from 'react';
import { Col, Row } from 'antd';
import Lottie from 'lottie-react';
import NotFound from '../../assets/lottie/not-found.json';

const NotFoundComponent = () => {
	return (
		<Row justify={'center'}>
			<Col>
				<div className="not_found_container">
					<Lottie animationData={NotFound} loop={true} />
				</div>
			</Col>
		</Row>
	);
};

export default NotFoundComponent;
