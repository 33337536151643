import React from 'react';
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import Button from 'components/button';

const LeadingCompanies = () => {
	let navigate = useNavigate();
	return (
		<Col span={24}>
			<Row className="leading_companies">
				<Col sm={24} xl={8} className="left_area">
					<img
						className="image"
						src={'https://femalecricket.com/wp-content/uploads/2021/09/Cricket-betting-in-india.jpg'}
						alt="leading companies"
					/>
				</Col>
				<Col sm={24} xl={2} />
				<Col sm={24} xl={14} className="right_area">
					<Row>
						<Col sm={24} xl={14}>
							<div className="text_area">
								<div className="title">
									<h1>
										Trust us <span>for the best rewards!</span>
									</h1>
								</div>
								<p className="desc">
									Take advantage of our exciting promotions and bonuses to enhance your betting experience. From welcome bonuses to special offers, we reward our loyal customers with exclusive perks and rewards.
								</p>
								{/* <Button onClick={() => navigate('/about')}>Know More</Button> */}
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};

export default LeadingCompanies;
