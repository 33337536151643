import React from 'react';
import { Col, Row } from 'antd';
import './technology.scss';

const TechnologyShowcase = ({ firstText, secondText, description, highlight = 1, list }) => {
	return (
		<Row className="technology_showcase">
			<Col sm={24} xl={24}>
				<Row justify="center" align="middle">
					<Col sm={24} xl={16}>
						<h1 className="title">
							{highlight === 1 ? (
								<>
									<span>{firstText}</span> {secondText}
								</>
							) : (
								<>
									{firstText} <span>{secondText}</span>
								</>
							)}
						</h1>
						<p className="desc">{description}</p>
					</Col>
				</Row>
			</Col>
			<Col sm={24} xl={24}>
				{list.map((list, index) => (
					<Row key={index} justify="center" align="middle" className="technology_list_container">
						<Col xl={8} className="title_area">
							<span className="title">{list?.title}</span>
						</Col>
						<Col xl={16} className="image_area">
							{list?.data.map((item, index) => (
								<div className="image_container" key={index}>
									<div className="image_box">
										<img src={item?.icon} alt={item?.name} />
									</div>
									<span className="name">{item.name}</span>
								</div>
							))}
						</Col>
					</Row>
				))}
			</Col>
		</Row>
	);
};

export default TechnologyShowcase;
