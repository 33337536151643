export const PAGE_DETAILS = {
	OUR_SERVICES: {
		ai_ml: {
			label: 'AI/ML',
			description:
				"Unlock the power of artificial intelligence and machine learning with our comprehensive services and solutions. At Upwyse, we empower businesses to harness the potential of AI and ML to drive innovation, enhance decision-making, and deliver personalized experiences. Explore how our AI/ML offerings can transform your business in today's data-driven world. Below are our services and solutions,",
			topics: [
				
			],
		},
		rpa: {
			label: 'Robotic process automation (RPA)',
			description:
				'Empower your organization with the efficiency and scalability of robotic process automation (RPA). At Upwyse, we offer comprehensive RPA services and solutions to automate repetitive tasks, streamline workflows, and drive digital transformation. Explore how our RPA offerings can revolutionize your business processes and enhance productivity',
			topics: [
				
			],
		},
		iot: {
			label: 'Internet of Things (IoT)',
			description: `Unlock the potential of the Internet of Things (IoT) with our comprehensive services and solutions. At
			Upwyse, we empower businesses to connect, monitor, and control devices and sensors in the digital
			world. Explore how our IoT offerings can drive innovation, optimize operations, and create new revenue
			streams for your organization.`,
			topics: [
			
			],
		},
	},
	BLOGS: [

	],
};
