import React from 'react';
import { ConfigProvider } from 'antd';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Navbar } from 'components/dropdown';
import { ROUTES } from 'routes';
import NotFoundComponent from 'components/not-found';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
	return (
		<ConfigProvider theme={{ token: { colorPrimary: '#00b96b' } }}>
			<Navbar />
			<Routes>
				{ROUTES.map((route) => (
					<Route {...route} key={route.path} />
				))}
				<Route path="*" element={<NotFoundComponent />} />
			</Routes>
			<ToastContainer hideProgressBar style={{ width: '400px' }} />
		</ConfigProvider>
	);
}

export default App;
