import React from 'react';
import { Col, Row } from 'antd';
import Image from '../../assets/svgs/our-mission/our-vision.svg';

const OurVision = () => {
	return (
		<Col span={24}>
			<Row className="our_mission">
				<Col sm={24} xl={8} className="left_area">
					<img className="image" src={Image} alt="leading companies" />
				</Col>
				<Col sm={24} xl={2} />
				<Col sm={24} xl={14} className="right_area">
					<Row>
						<Col sm={24} xl={24}>
							<div className="text_area">
								<div className="title">
									<h1>
										Our <span>Vision</span>
									</h1>
								</div>
								<span className="subtitle">Inspiring Technopreneurs, Driving Diversity</span>
								<p className="desc">
									To continually cultivate a diverse ecosystem of technopreneurs across various industries. We aim to be a catalyst
									for digital innovation, transforming concepts into world-class digital business models. We strive to lead the
									market by fostering a culture of creativity, adaptability, and excellence in digital solutions.
								</p>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};

export default OurVision;
