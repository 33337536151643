import React from 'react';
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
// import Button from 'components/button';
import ArrowRight from 'assets/svgs/arrow-right.svg';

const SERVICES = [
	{
		name: 'AI/ML',
		desc: 'Unlock the power of artificial intelligence and machine learning with our comprehensive services and solutions',
		image: require('../../assets/images/featured-resources/unveiling.png'),
		path: '/ai_ml',
	},
	// {
	// 	name: 'RPA',
	// 	desc: 'Empower your organization with the efficiency and scalability of robotic process automation',
	// 	image: require('../../assets/images/featured-resources/navigating.png'),
	// 	path: '/rpa',
	// },
	// {
	// 	name: 'IoT (Internet of Things)',
	// 	desc: 'We provide Expert guidance and strategic planning to define IoT initiatives aligned with your business objectives',
	// 	image: require('../../assets/images/featured-resources/flourishing.png'),
	// 	path: '/iot',
	// },
];

const OurServices = () => {
	let navigate = useNavigate();
	return (
		<Row className="our_services" id="terms">
			<Col sm={24} xl={24}>
				<Row
					justify={'center'}
					align={'middle'}
					gutter={[20, 20]}
					style={{
						margin: 0,
					}}>
					<Col sm={24} xl={16}>
						<h1 className="title">
							<span>Terms</span> & <span>Policy</span>
						</h1>
						<p className="desc">
							We reserve the right to update or modify these terms and conditions at any time without prior notice. Users are encouraged to review the terms periodically for any changes.
						</p>
					</Col>
					<Col span={24} className="feature_container">
						<Row gutter={[16, 0]} className="scroll_on_mobile" >
							<ul className='desc'>
								<li>Eligibility: Users must be of legal age (18 years or older) and in a jurisdiction where online sports betting is legal to participate in IPL cricket betting on our platform.</li>
								<li>Betting Rules: Users must adhere to the betting rules and regulations outlined on our platform. This includes understanding the types of bets available, minimum and maximum bet amounts, and any specific rules related to IPL cricket betting.</li>
								<li>Responsible Gambling: We promote responsible gambling practices and encourage users to bet within their means. Users should gamble for entertainment purposes only and avoid chasing losses. We offer tools such as deposit limits and self-exclusion options to help users manage their gambling activities responsibly.</li>
								<li>Fair Play: We are committed to ensuring fair play and integrity in all our betting markets. Any form of cheating, collusion, or manipulation of outcomes will result in immediate account suspension and may be reported to relevant authorities.</li>
								<li>Deposits and Withdrawals: Users can deposit funds into their account using approved payment methods. Withdrawal requests are subject to verification checks and may be processed within a specified timeframe. Users may be required to provide additional documentation for verification purposes.</li>
								<li>Privacy and Security: We prioritize the privacy and security of our users' personal and financial information. We employ industry-standard security measures to safeguard data and adhere to strict privacy policies.</li>
								<li>Changes to Terms: We reserve the right to update or modify these terms and conditions at any time without prior notice. Users are encouraged to review the terms periodically for any changes.</li>
								<li>Disclaimer: While we strive to provide accurate and reliable information, we do not guarantee the accuracy, completeness, or reliability of any content on our platform. Users acknowledge that betting on IPL cricket matches involves inherent risks, and we shall not be liable for any losses incurred as a result of such betting activities.</li>
								<li>By using our platform, users agree to abide by these terms and conditions. Failure to comply with these terms may result in account suspension or termination.</li>
							</ul>
						</Row>
					</Col>
					{/* <Col>
						<Button
							style={{
								borderRadius: '100px',
								minHeight: '50px',
								padding: '0 20px',
							}}>
							View all articles
						</Button>
					</Col> */}
				</Row>
			</Col>
		</Row>
	);
};

export default OurServices;
