import React, { useState } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Dropdown from './Dropdown';

function Navbar() {
	const [click, setClick] = useState(false);
	const [dropdown, setDropdown] = useState(false);

	const handleClick = () => setClick(!click);
	const closeMobileMenu = () => {
		setDropdown(false);
		setClick(false);
	};

	const onMouseEnter = () => {
		if (window.innerWidth < 960) {
			// setDropdown(false);
		} else {
			setDropdown(true);
		}
	};

	const onMouseLeave = () => {
		if (window.innerWidth < 960) {
			setDropdown(false);
		} else {
			// setDropdown(false);
		}
	};

	return (
		<>
			<nav className="navbar">
				<Link to="#" className="navbar-logo" onClick={closeMobileMenu}>
					cric x thamb jam
					{/* <i className="fab fa-firstdraft" /> */}
				</Link>
				<div className="menu-icon" onClick={handleClick}>
					<i className={click ? 'fas fa-times' : 'fas fa-bars'} />
				</div>
				<ul className={click ? 'nav-menu active' : 'nav-menu'}>
					<li className="nav-item">
						<Link to="#home" className="nav-links" onClick={closeMobileMenu}>
							Home
						</Link>
					</li>
					<li className="nav-item">
						<Link to="#about" className="nav-links" onClick={closeMobileMenu}>
							About us
						</Link>
					</li>
					{/* <li className="nav-item" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
						<Link className="nav-links" onClick={closeMobileMenu}>
							Services <i className="fas fa-caret-down" />
						</Link>
						{dropdown && (
							<Dropdown
								closeMenu={() => {
									onMouseLeave();
									closeMobileMenu();
								}}
							/>
						)}
					</li> */}
					<li className="nav-item">
						<Link to="#terms" className="nav-links" onClick={closeMobileMenu}>
							Terms & Conditions
						</Link>
					</li>
					<li className="nav-item">
						<Link to="#contact" className="nav-links" onClick={closeMobileMenu}>
							Contact Us
						</Link>
					</li>
					{/* <li className="nav-item">
						<Link to="/join-with-us" className=" nav-links nav-links-mobile" onClick={closeMobileMenu}>
							Connect with us
						</Link>
					</li> */}
				</ul>
				{/* <Button /> */}
			</nav>
		</>
	);
}

export default Navbar;
