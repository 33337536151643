import { AiFillCheckCircle, AiFillInfoCircle, AiFillWarning, AiFillCloseCircle } from 'react-icons/ai';

export const ToastifyNotification = ({ type, title, body }) => {
	const IconType = {
		INFO: <AiFillInfoCircle />,
		WARNING: <AiFillWarning />,
		ERROR: <AiFillCloseCircle />,
		SUCCESS: <AiFillCheckCircle />,
	};

	const Icon = IconType[type];
	return (
		<div className={`push-notification ${type}`}>
			<div className="indicator" />
			<div className="icon">{Icon}</div>
			<div className="push-notification-content">
				<span className="push-notification-title">{title}</span>
				<p className="push-notification-text">{body}</p>
			</div>
		</div>
	);
};
