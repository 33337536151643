import React from 'react';
import { Row, Col } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'components/button';
import './footer.scss';

const Footer = () => {
	const navigate = useNavigate();
	return (
		<Col span={24} className="footer" id="contact">
			<Row justify={'center'}>
				<Col sm={22} xl={20}>
					<Row>
						<Col xl={16} sm={24} className="left_area">
							<h1>
								<span className="blue_text">Let’s Bid</span> achieve something big{' '}
							</h1>
							<span className="desc">
								The minimum bid amount for IPL cricket betting on our platform is set at Rs.1000/- <br></br>
								Withdrawal requests are processed within a timeframe of 5 hours from the time of match finished.
							</span>
							<a href="https://wa.me/+919597999250" target="_blank" class="info-email">
								<Button className="button_style">Connect with WhatsApp</Button>
							</a>
						</Col>
						<Col span={8} className="right_area">
							<div>
								<picture>
									{/* <source
									srcset="https://assets.sports.dev.roanuz.com/uploads-v5/cricket/website/lading page/3nov2023/mobile1_banner_hand@3x.png, https://assets.sports.dev.roanuz.com/uploads-v5/cricket/website/lading page/3nov2023/mobile2_banner_hand@2x.png 2x, https://assets.sports.dev.roanuz.com/uploads-v5/cricket/website/lading page/3nov2023/mobile3_banner_hand.png 3x"
									media="(max-width: 767px)"
								/>
								<source
									srcset="https://assets.sports.dev.roanuz.com/uploads-v5/cricket/website/lading page/3nov2023/tablet-banner.png, https://assets.sports.dev.roanuz.com/uploads-v5/cricket/website/lading page/3nov2023/tablet-banner@2x copy.png 2x, https://assets.sports.dev.roanuz.com/uploads-v5/cricket/website/lading page/3nov2023/tablet-banner@3x copy.png 3x"
									media="(max-width: 1193px)"
								/>
								<source srcset="https://assets.sports.dev.roanuz.com/uploads-v5/cricket/website/lading page/3nov2023/desktop.png, https://assets.sports.dev.roanuz.com/uploads-v5/cricket/website/lading page/3nov2023/Frame-desktop-@2x.png 2x, https://assets.sports.dev.roanuz.com/uploads-v5/cricket/website/lading page/3nov2023/Frame -desktop-@3x.png 3x" /> */}
									<img src={require('../../assets/images/QRCode.jpeg')} alt="UPI image" srcset="" class="" />
									<span
										className="name"
										style={{
											fontWeight: 'bold',
											color: '#000',
											padding: '10px',
										}}>
										UPI Id: <a href="upi://pay?pa=autoclubjaba-1@okaxis&pn=ImmanuvelSebastin&cu=INR">autoclubjaba-1@okaxis</a>
									</span>
								</picture>
							</div>
						</Col>
					</Row>
					<Row>
						<Col span={24} className="bottom_area">
							<Row>
								<Col xl={12} sm={24} className="left_area">
									<span className="name">Contact us</span>
									<a href="mailto:cricxthambjam@gmail.com" class="info-email">
										cricxthambjam@gmail.com
									</a>
									<a href="https://wa.me/+919597999250" target="_blank" class="info-email">
										+91 95979 99250
									</a>
								</Col>
								{/* <Col xl={12} sm={24} className="right_area">
									<ul className="links">
										<li>
											<Link>About us</Link>
										</li>
										<li>
											<Link>Services</Link>
										</li>
										<li>
											<Link>Blogs</Link>
										</li>
										<li>
											<Link>Contact us</Link>
										</li>
									</ul>
									<span>Copyright © 2023 Upwyse, All rights reserved.</span>
								</Col> */}
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};

export default Footer;
