import React from 'react';
import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';
import BannerSection from 'components/top-section';
import { PAGE_DETAILS } from 'constants/data';
import Footer from 'components/footer';
import Collapsible from 'components/collapsible';
import './services.scss';

const Services = () => {
	const { serviceName } = useParams();

	if (!PAGE_DETAILS.OUR_SERVICES?.[serviceName]?.label) {
		return <h1>No Data Found</h1>;
	}

	return (
		<Row justify={'center'} className="services">
			<Col span={24}>
				<BannerSection
					isAbout
					title="Our Services"
					headText={PAGE_DETAILS.OUR_SERVICES[serviceName].label}
					desc={PAGE_DETAILS.OUR_SERVICES[serviceName].description}
				/>
			</Col>
			<Col sm={24} xl={20} className="feature_container">
				<Row className="scroll_on_mobile web_view">
					{PAGE_DETAILS.OUR_SERVICES[serviceName]?.topics?.map((topic, index) => (
						<Col sm={24} xl={8} className="container" key={index}>
							<div className="text_container">
								<h2 className="title">{topic?.title}</h2>
								<span className="description">{topic?.description}</span>
							</div>
						</Col>
					))}
				</Row>
				<Row className="mobile_view">
					{PAGE_DETAILS.OUR_SERVICES[serviceName]?.topics?.map((topic, index) => (
						<Col key={index} sm={24} xl={8} className="collapsible_container">
							<Collapsible key={index} title={topic?.title} description={topic?.description} />
						</Col>
					))}
				</Row>
			</Col>
			<Col sm={24} xl={19} className="feature_container">
				<p className="footer_text">
					At Upwyse, we're committed to delivering AI/ML solutions that drive tangible business results and unlock new possibilities.
					<span> Connect with us</span> to learn more about how we can tailor our AI/ML services to address your unique challenges and
					opportunities.
				</p>
			</Col>
			<Col span={24}>
				<Footer />
			</Col>
		</Row>
	);
};

export default Services;
